<template>
    <div class="mijnps-edit">
        <template v-if="!fotoVerzonden">
            <button class="btn" @click="$router.go(-1)">Ga terug</button>
            <h3 class="oleo">Upload nieuwe foto</h3>
            <p style="margin-bottom: 2rem">
                Hieronder kun je een of meerdere foto's uploaden. We gebruiken één profielfoto en kunnen daarnaast nog
                enkele andere foto's weergeven die ook weer een breder beeld geven.
            </p>

            <form>
                <vue-dropzone
                    ref="fotoDropzone"
                    id="fotoDropzone"
                    :duplicateCheck="true"
                    :options="dropzoneOptions"
                    @vdropzone-complete="completeResponse"
                    @vdropzone-error="handleError"
                    @vdropzone-sending="handleSend"
                    @vdropzone-queue-complete="queueComplete"
                ></vue-dropzone>
                <input
                    type="submit"
                    class="btn btn-rounded"
                    style="width: 100%"
                    value="Verstuur"
                    :disabled="fotoVerzendingActief"
                    @click.prevent="verwerkFoto"
                />
            </form>
        </template>
        <template v-else>
            <h3 class="oleo">Foto verzonden</h3>
            <p>
                Het verzenden naar PartnerSelect is gelukt! De foto's nemen we bij de gegevens op. Bij de beschrijving
                plaatsen we één foto. De nieuwe foto wordt na verwerking zichtbaar op Mijn PartnerSelect.
            </p>
            <button class="btn" @click="$router.push('/mijn-beschrijving')">Terug naar mijn beschrijving</button>
        </template>
    </div>
</template>

<script>
    import vue2dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import { mapGetters, mapActions } from 'vuex';
    import { ENDPOINTS_ROOT } from '../PS-Instellingen/Server.config';

    export default {
        data() {
            return {
                fotoVerzonden: false,
                fotoVerzendingActief: false,
                dropzoneOptions: {
                    url: `${ENDPOINTS_ROOT}/foto_upload.php`,
                    // url: "https://httpbin.org/anything", // Alleen om te testen
                    thumbnailWidth: 180,
                    uploadMultiple: false,
                    maxFilesize: 15,
                    acceptedFiles: 'image/*,.jpg,.jpeg,.gif,.png',
                    addRemoveLinks: true,
                    autoProcessQueue: false,
                    paramName: 'fileToUpload',

                    dictDefaultMessage: 'Sleep een bestand hier naartoe of klik op dit veld om te uploaden.',
                    dictFallbackMessage: 'Deze browser ondersteunt het uploaden niet.',
                    dictFileTooBig: 'Dit bestand is te groot om te uploaden',
                    dictInvalidFileType: 'Dit bestandstype wordt niet geaccepteerd',
                    dictResponseError: 'Bestand kon niet geupload worden',
                    dictRemoveFile: 'Verwijderen',
                    dictCancelUpload: 'Annuleer',
                    dictUploadCanceled: 'Upload geannuleerd',
                    dictCancelUploadConfirmation: 'Weet je zeker dat je dit bestand niet wilt uploaden?',
                    dictRemoveFileConfirmation: 'Weet je zeker dat je dit bestand niet wilt uploaden?',
                },
            };
        },
        mounted() {
            scrollTo(0, 0);
        },
        components: {
            vueDropzone: vue2dropzone,
        },
        methods: {
            ...mapActions(['addNotification']),
            handleSend(file, xhr, formData) {
                formData.append('Relatornummer', this.relatorNummer);
                formData.append('idToken', this.getIdToken);
            },
            verwerkFoto() {
                this.fotoVerzendingActief = true;
                this.$refs.fotoDropzone.processQueue();
            },
            completeResponse(response) {
                if (response.xhr && response.xhr.status === 200) {
                    this.addNotification('Bestand verzonden.');
                }
            },
            queueComplete() {
                this.fotoVerzonden = true;
                this.fotoVerzendingActief = false;
                this.$store.dispatch('getUserData');
            },
            clearUploadField() {
                this.$refs.fotoDropzone.removeAllFiles();
            },
            handleError(file, message, xhr) {
                // console.log(message);

                // ERRORS voor verzending
                if (message.match(/bestandstype wordt niet geaccepteerd/i)) {
                    this.addNotification(
                        file.name + ' - Is geen geldig beeldbestand. Het bestand wordt uit de wachtrij verwijderd.'
                    );
                } else if (message.match(/bestand is te groot om te uploaden/i)) {
                    this.addNotification(
                        file.name + ' - Bestand is te groot. Het bestand wordt uit de wachtrij verwijderd.'
                    );
                } else if (xhr && xhr.response) {
                    // ERRORS na verzending
                    if (message.match(/BESTAND_TE_GROOT/i)) {
                        this.addNotification(
                            file.name + ' - Bestand is te groot. Het bestand wordt uit de wachtrij verwijderd.'
                        );
                    }
                    if (message.match(/BESTAND_GEEN_PNG_GIF_JPG/i)) {
                        this.addNotification(
                            file.name +
                                ' - Bestand is geen geldig beeldbestand. Het bestand wordt uit de wachtrij verwijderd.'
                        );
                    }
                } else {
                    this.addNotification(
                        file.name + ' - Er ging iets mis met uploaden. Het bestand wordt uit de wachtrij verwijderd'
                    );
                }

                this.$refs.fotoDropzone.removeFile(file);
            },
        },
        computed: {
            ...mapGetters(['relatorNummer', 'getIdToken']),
        },
    };
</script>
